export async function getAllTemplates() {
    const response = await fetch('/api/template');
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else {
        console.error(response);
        return [];
    }
}

export async function getTemplate(name, woco) {
    const response = await fetch(`/api/template/${name}/woco/${woco}`);
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else {
        console.error(response);
        return {};
    }
}

export async function getWocos() {
    const response = await fetch(`/api/wocos`);
    if (response.status === 200) {
        const json = await response.json();
        return json.data;
    } else {
        console.error(response);
        return {};
    }
}

export async function getDownloadLink(name, woco) {
    const response = await fetch(`/api/template/${name}/woco/${woco}/download`);
    if (response.status === 200) {
        const json = await response.json();
        return json;
    } else {
        console.error(response);
        return [];
    }
}

export async function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch('/api/file', {
        method: 'POST',
        body: formData,
    });
    if (response.status == 409) {
        return { error: 'This file already exists. Upload another one' };
    } else {
        const data = await response.json();
        return data;
    }
}

export async function submitTemplate(uuid, name, woco, fields) {
    const response = await fetch('/api/template', {
        method: 'POST',
        body: JSON.stringify({
            uuid,
            name,
            woco,
            detected_fields: fields,
        }),
    });
    let json;
    try {
        json = await response.json();
    } catch (err) {
        return { error: 'Error while submitting the template' };
    }
    if (response.status == 201) {
        return { message: 'Success' };
    } else {
        return { error: (json.error !== undefined ? json.error : 'Error while submitting the template') };
    }
}

export async function deleteTemplate(name, woco) {
    const response = await fetch(`/api/template/${name}/woco/${woco}`, {
        method: 'DELETE'
    });
    const json = await response.json();
    if (response.status == 200) {
        return { message: 'Deleted' };
    } else {
        return { error: json.error }
    }
}