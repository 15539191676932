<template>
  <div class="table">
    <table class="table" style="width: 100%">
      <thead>
        <tr>
          <th v-for="cell in header" :key="cell">{{ cell }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rows" :key="row.name">
          <td>{{ row.name }}</td>
          <td>{{ row.woco }}</td>
          <td>{{ row.created_at }}</td>
          <td>{{ row.total }}</td>
          <td><DropDown
                defaultText="Velden"
                :options="row.detectedFields"
                :setSelectedOption="() => {}"
                width="auto"
             />
          </td>
          <td><a @click="downloadClick(row.name, row.woco)">Download</a></td>
          <td><a @click.prevent="(event) => deleteClick(row)">Verwijderen</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DropDown from '@/components/DropDown.vue';

export default {
  name: 'Table',
  components: {
    DropDown,
  },
  props: {
    header: Array,
    rows: Array,
    downloadClick: Function,
    deleteClick: Function,
  }
}
</script>

<style>

</style>