import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import * as auth from '@/handlers/auth';
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const [loggedIn, user] = await auth.userIsLoggedIn();
  if (!loggedIn) {
    if (to.name !== 'Login') {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    store.commit('setUser', user);
    if (to.name === 'Login') {
      next({ name: 'Home' });
    } else {
      next();
    }
  }
});

export default router
