<template>
  <div class="file">
      <label class="file-label">
        <input class="file-input" type="file" name="template" :accept="fileType" @change="onChange">
        <span class="file-cta">
          <span class="file-icon">
            <ion-icon name="cloud-upload"></ion-icon>
          </span>
          <span class="file-label">
            Selecteer een bestand
          </span>
        </span>
        <span v-if="fileName" class="file-name">
          {{ fileName }}
        </span>
      </label>
    </div>
</template>

<script>
export default {
  props: {
    setFile: Function,
    fileType: String,
  },
  data() {
    return {
      fileName: null,
    }
  },
  methods: {
    onChange(event) {
      const file = event.target.files[0];
      if (file.type == this.fileType) {
        this.fileName = file.name;
        this.setFile(file);
      } else {
        this.setFile(null);
      }
    }
  }
}
</script>

<style>

</style>