<template>
  <div class="section home-section">
      <div class="container">
        <div class="home-header">
          <span>{{user}}</span>
        </div>
        <div class="home">
          <Modal 
            title="Template verwijderen"
            content="Weet je zeker dat je deze template permanent wil verwijderen?"
            :isActive="!!deleteModal"
            buttonText="Verwijderen"
            buttonType="danger"
            :onClick="deleteTemplate"
            :onClose="(e) => deleteModal = null"
            :isLoading="isDeleteLoading"
          />
          <div class="columns">
            <div class="column is-two-thirds">
              <div class="columns">
                <div class="column is-three-fifths">
                  <h4 class="title is-4">Templates</h4>
                  <h6 class="subtitle is-6">Lijst met bestaande templates gesorteerd per woco.</h6>
                </div>
                <div class="column">
                  <DropDown
                    :defaultText="selectedOptionTable" 
                    :options="filteredOptionsTable" 
                    :setSelectedOption="setSelectedOptionTable"
                  />
                </div>
                <div class="column">
                  <p class="p-2">
                    Resultaten: {{filteredRows.length}}
                  </p>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <Table 
                    :header="header"
                    :rows="filteredRows"
                    :deleteClick="(row) => setDeleteModal(row)"
                    :downloadClick="downloadTemplate"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="columns">
                <div class="column">
                  <h4 class="title is-4 pb-2">Template aanmaken</h4>
                  <h6 class="subtitle is-6">Upload hier je template om deze aan te maken.</h6>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <FileInput :setFile="setFile" :fileType="MSWordType" />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div v-if="isSuccess" class="notification is-success">
                    Template is aangemaakt. Je template zou nu in de tabel moeten staan.
                  </div>
                  <div v-else-if="isLoading && file"><p>Verwerken...</p></div>
                  <div v-else-if="isFileError" class="notification is-danger">
                    Er is iets misgegaan. Probeer het later opnieuw.
                  </div>
                  <div v-else-if="invalidFileError" class="notification is-danger">
                    Dit bestand is ongeldig. Probeer het later opnieuw.
                  </div>
                  <Form v-else-if="file">
                    <div v-if="hasNoFieldsError" class="notification is-danger">
                      Er zijn in dit bestand geen velden gedetecteerd.
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p class="pb-2 pt-2 has-text-weight-bold">Template naam</p>
                        <TextInput
                          placeholder="Template name..."
                          label="Template name"
                          :updateValue="setTemplateName"
                        />
                      </div>
                      <div class="column">
                        <p class="pb-2 pt-2 has-text-weight-bold">Selecteer een woco</p>
                        <DropDown
                          :defaultText="selectedOptionForm" 
                          :options="filteredOptionsForm" 
                          :setSelectedOption="setSelectedOptionForm"
                        />
                      </div>
                    </div>
                    <p class="pb-2 has-text-weight-bold">Selecteer een service</p>
                    <DropDown
                      :defaultText="selectedOptionService" 
                      :options="filteredOptionsService" 
                      :setSelectedOption="setSelectedOptionService"
                      :disabled="true"
                    />
                    <p class="pb-5 pt-5 has-text-weight-bold">Match de velden met de juiste variabelen</p>
                    <div v-for="field in Object.keys(templateFields)" :key="field" class="columns">
                      <div class="column is-one-third">
                        <p class="pb-2 pt-2 pr-2 has-text-weight-normal">{{ field }}:</p>
                      </div>
                      <div class="column">
                        <DropDown
                          :defaultText="templateFields[field]"
                          :options="possibleFields"
                          :setSelectedOption="(value) => updateFieldsMapping(value, field)"
                        />
                      </div>
                    </div>
                    <div class="buttons is-right">
                      <button class="button is-info" :class="{'is-loading': isButtonLoading}" @click="submitTemplate">Aanmaken</button>
                    </div>
                    <div v-if="isError" class="notification is-danger">
                      Het aanmaken van de template is mislukt. Zorg dat alle velden correct zijn ingevuld en probeer het opnieuw.
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue';
import DropDown from '@/components/DropDown.vue';
import FileInput from '@/components/FileInput.vue';
import Form from '@/components/Form.vue';
import TextInput from '@/components/TextInput.vue';
import Modal from '../components/Modal.vue';

import * as DocsService from '@/services/DocsService';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    Table,
    DropDown,
    FileInput,
    Form,
    TextInput,
    Modal,
  },
  data() {
    return {
      header: [ "Naam", "Woco", "Aangemaakt op", "Downloads", "Velden", "", " "],
      rows: [],
      formOptions: [],
      services: [
        'Clients Service',
      ],
      selectedOptionTable: "Test-woco",
      selectedOptionForm: "Lefier",
      selectedOptionService: "Clients Service",
      file: null,
      fileBeingUploaded: null,
      templateName: '',
      possibleFields: ["name", "postal_code", "street", "house_number", "email", "phone", "city", "country", "date", "salutation", "panels"],
      templateFields: {},
      isLoading: false,
      isButtonLoading: false,
      isDeleteLoading: false,
      isError: false,
      isSuccess: false,
      isFileError: false,
      invalidFileError: false,
      deleteModal: null,
      MSWordType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }
  },

  mounted() {
    this.fetchDocsService();
  },

  methods: {
    setDeleteModal(row) {
      this.deleteModal = row
    },

    async deleteTemplate() {
      this.isDeleteLoading = true;
      const result = await DocsService.deleteTemplate(this.deleteModal.name, this.deleteModal.woco);
      if (result.message === 'Success') {
        this.rows = this.rows.filter(row => !(row.name == this.deleteModal.name && row.woco == this.deleteModal.woco))
        this.deleteModal = null;
      }
      this.isDeleteLoading = false;
    },

    async downloadTemplate(name, woco) {
      const downloadLink = await DocsService.getDownloadLink(name, woco);
      const blobResponse = await fetch(downloadLink);
      const blob = await blobResponse.blob();
      const blobDownloadLink = window.URL.createObjectURL(blob);
      //window.location.assign(blobDownloadLink);

      // Use <a> approach to set filename properly
      const anchor = document.createElement('a');
      anchor.style = 'display: none;'
      anchor.href = blobDownloadLink;
      anchor.download = `${woco}_${name}.docx`;
      anchor.click();
      window.URL.revokeObjectURL(blobDownloadLink);
      anchor.remove();
    },

    async fetchDocsService() {
      const templates = await DocsService.getAllTemplates();
      const wocos = await DocsService.getWocos();
      this.formOptions = wocos;
      
      this.rows = templates;
      // this.options = [...new Set(templates.map(t => t.woco))];
      this.selectedOptionTable = this.tableOptions[0];
    },

    setSelectedOptionTable(option) {
      this.selectedOptionTable = option;
    },
    setSelectedOptionForm(option) {
      this.selectedOptionForm = option;
    },
    setSelectedOptionService(option) {
      this.selectedOptionService = option;
    },
    setTemplateName(name) {
      this.templateName = name;
    },

    async setFile(file) {
      this.fileBeingUploaded = null;
      this.templateFields = {};
      this.file = null;

      if (file === null) {
        this.invalidFileError = true;
        return;
      }

      console.log(file);

      this.invalidFileError = false;
      this.isError = false;
      this.isSuccess = false;
      this.file = file;
      this.isLoading = true;
      // Upload file
      const fileUploadData = await DocsService.uploadFile(file);
      fileUploadData.detectedFields.forEach(field => {
        this.$set(this.templateFields, field, this.possibleFields[0]);
      })
      console.log(fileUploadData);
      if (fileUploadData.name === undefined) {
        this.isFileError = true;
      } else {
        this.isFileError = false;
      }
      // this.possibleFields = fileUploadData.detectedFields;
      this.fileBeingUploaded = fileUploadData;
      this.isLoading = false;
    },

    updateFieldsMapping(value, field) {
      this.$set(this.templateFields, field, value);
    },

    async submitTemplate() {
      this.isButtonLoading = true;
      const templateSubmitData = await DocsService.submitTemplate(
        this.fileBeingUploaded.uuid,
        this.templateName,
        this.selectedOptionForm,
        this.templateFields,
      );      
      this.isButtonLoading = false;
      if (!templateSubmitData.error) {
        this.isSuccess = true;
        const template = await DocsService.getTemplate(this.templateName, this.selectedOptionForm);
        this.rows.push(template);
        this.file = null;
        this.fileBeingUploaded = null;
      } else {
        this.isError = true;
      }
    },
  },

  computed: {
    ...mapState(['user']),
    filteredOptionsTable() {
      return this.tableOptions.filter(option => option !== this.selectedOptionTable);
    },
    filteredOptionsForm() {
      return this.formOptions.filter(option => option !== this.selectedOptionForm);
    },
    filteredOptionsService() {
      return this.services.filter(option => option !== this.selectedOptionService);
    },
    filteredRows() {
      return this.rows.filter(row => row.woco === this.selectedOptionTable);
    },
    tableOptions() {
      return [...new Set(this.rows.map(row => row.woco))];
    },
    hasNoFieldsError() {
      if (this.fileBeingUploaded && Object.keys(this.templateFields).length === 0) return true;
      return false;
    },
  }
}
</script>

<style>

  .section.home-section {
    padding-top: 0;
  }

  .home-header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
  }
</style>
