<template>
  <div class="section login-section">
    <div class="container">
      <div class="login">
        <div class="columns">
          <div class="column">
            <div class="box login-box" style="width: 450px; margin: 0 auto;">
              <h1 class="title">Login</h1>
              <p class="subtitle">
                Vul uw e-mailadres in en druk op verstuur.<br>
                Vervolgends ontvangt u een OTP code om mee in te loggen.<br>
              </p>
              <div v-if="isError && !otpSent" class="notification is-danger">
                Geen gebruiker gevonden met deze e-mail.
              </div>
              <template v-if="otpSent">
                <div v-if="isError" class="notification is-danger">
                  OTP code incorrect.
                </div>
                <p>OTP code is verstuurd. Controleer uw e-mail.</p>
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" placeholder="OTP code" v-model="otp">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button class="button is-link" @click="login">Login</button>
                  </div>
                </div>
                <div class="request-again">
                  <span>Werkt de code niet?</span>
                  <button type="button" class="button is-text"
                    @click="requestOTP"
                  >
                    Vraag hier een nieuwe aan.
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" placeholder="Email" v-model="email">
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button class="button is-link" @click="requestOTP">Verstuur</button>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  components: {
    
  },
  data() {
    return {
      otpSent: false,
      otp: '',
      email: '',
      isError: false,
      errorMessage: '',
    }
  },
  methods: {
    async requestOTP() {
      const response = await fetch(`/api/login/${this.email}`);
      if (response.status === 200) {
        this.isError = false;
        this.otpSent = true;
      } else {
        this.isError = true;
        const json = await response.json();
        this.errorMessage = json.error;
      }
    },
    async login() {
      const response = await fetch(
        `/api/otp`,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({email: this.email, otp: this.otp}),
        }
      );
      if (response.status === 200) {
        this.isError = false;
        this.$router.push({ name: 'Home' });
      } else {
        this.isError = true;
        const json = await response.json();
        this.errorMessage = json.error;
      }
    }
  },
}
</script>

<style>

  .login-section {
    width: 100%;
    height: 100%;
    background: #83a4d4;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #b6fbff, #83a4d4);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #b6fbff, #83a4d4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }

  .login-box {
    background-color: whitesmoke;
  }

  div.request-again {
    display: flex;
    align-items: center;
  }
</style>