import * as requests from '@/handlers/requests';

export async function getAllTemplates() {
    const templates = await requests.getAllTemplates();
    return templates['templates'].map(t => {
        let detectedFields;

        try {
            const res = JSON.parse(`${t.detected_fields}`);
            detectedFields = Object.keys(res);
        } catch (error) {
            detectedFields = ['Niet beschikbaar']
        }

        return {
            name: t.name,
            woco: t.woco,
            created_at: new Date(t.created_at).toLocaleString(),
            total: t.times_generated,
            detectedFields,
        };
    });
}

export async function getTemplate(name, woco) {
    const template = await requests.getTemplate(name, woco);
    let detectedFields;

    try {
        const res = JSON.parse(`${template.detected_fields}`);
        detectedFields = Object.keys(res);
    } catch (error) {
        detectedFields = ['Niet beschikbaar']
    }

    return {
        name: template.name,
        woco: template.woco,
        created_at: new Date(template.created_at).toLocaleString(),
        total: template.times_generated,
        detectedFields,
    };
}

export async function getDownloadLink(name, woco) {
    const downloadLink = await requests.getDownloadLink(name, woco);
    return downloadLink['link'];
}

export async function getWocos() {
    const wocos = await requests.getWocos();
    return wocos;
}

export async function uploadFile(file) {
    const uploadFileData = await requests.uploadFile(file);
    console.log(uploadFileData);
    if (uploadFileData.error === undefined) {
        return {
            uuid: uploadFileData.id,
            name: file.name,
            detectedFields: uploadFileData.detected_fields,
        };
    } else {
        return {};
    }
}

export async function submitTemplate(uuid, name, woco, fields) {
    const submitTemplateData = await requests.submitTemplate(uuid, name, woco, JSON.stringify(fields));
    console.log(submitTemplateData);
    if (submitTemplateData.error === undefined) {
        return {
            message: 'Success',
        };
    } else {
        return { error: submitTemplateData.error };
    }
}

export async function deleteTemplate(name, woco) {
    const deleted = await requests.deleteTemplate(name, woco);
    if (deleted.error === undefined) {
        return {
            message: 'Success',
        };
    } else {
        return { error: deleted.error };
    }
}