<template>
  <div class="field">
    <div class="control">
      <input 
        class="input" 
        type="text" 
        :placeholder="placeholder"
        v-model="value"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    updateValue: Function,
  },
  data() {
    return {
      value: '',
    }
  },
  watch: {
    value() {
      this.updateValue(this.value);
    }
  }
}
</script>

<style>

</style>