<template>
  <div :class="{'dropdown': true, 'is-active': isActive}">
    <div class="dropdown-trigger">
      <button 
        @click="toggleActive" 
        class="button" 
        aria-haspopup="true" 
        aria-controls="dropdown-menu"
        :style="`${displayWidth} justify-content: space-between;`"
        :disabled="options.length === 0"
      >
        <span>{{defaultText}}</span>
        <span class="icon is-small">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a 
          class="dropdown-item" 
          v-for="option in options" 
          :key="option"
          @click="() => onSelection(option)"
        >
          {{option}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    defaultText: String,
    setSelectedOption: Function,
    disabled: Boolean,
    width: String,
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    onSelection(option) {
      this.isActive = false;
      this.setSelectedOption(option);
    }
  },
  computed: {
    displayWidth() {
      if (this.width === 'auto') {
        return ''
      } else if (typeof this.width === 'number') {
        return `width: ${this.width}px;`;
      } else {
        return 'width: 200px;'
      }
    }
  }
}
</script>

<style>

</style>