<template>
  <div :class="{'is-active': isActive}" class="modal is-clipped">
    <div class="modal-background"></div>
    <div
      class="modal-card" 
      style="width: 500px;"  
    >
      <header class="modal-card-head">
        <p class="modal-card-title">{{title}}</p>
      </header>
      <section class="modal-card-body">
        {{content}}
      </section>
      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <button @click="onClose" class="button">Annuleren</button>
        <button @click="onClick" :class="`button is-${buttonType} ${isLoading ? 'is-loading' : ''}`">{{buttonText}}</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    buttonText: String,
    buttonType: String,
    isActive: Boolean,
    onClick: Function,
    onClose: Function,
    isLoading: Boolean,
  }
}
</script>